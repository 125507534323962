exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[artist]-[release]-js": () => import("./../../../src/pages/[artist]/[release].js" /* webpackChunkName: "component---src-pages-[artist]-[release]-js" */),
  "component---src-pages-[artist]-albums-album-name-js": () => import("./../../../src/pages/[artist]/albums/[albumName].js" /* webpackChunkName: "component---src-pages-[artist]-albums-album-name-js" */),
  "component---src-pages-[artist]-albums-index-js": () => import("./../../../src/pages/[artist]/albums/index.js" /* webpackChunkName: "component---src-pages-[artist]-albums-index-js" */),
  "component---src-pages-[artist]-index-js": () => import("./../../../src/pages/[artist]/index.js" /* webpackChunkName: "component---src-pages-[artist]-index-js" */),
  "component---src-pages-[artist]-songs-index-js": () => import("./../../../src/pages/[artist]/songs/index.js" /* webpackChunkName: "component---src-pages-[artist]-songs-index-js" */),
  "component---src-pages-[artist]-songs-track-name-js": () => import("./../../../src/pages/[artist]/songs/[trackName].js" /* webpackChunkName: "component---src-pages-[artist]-songs-track-name-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-playlist-playlist-redirect-js": () => import("./../../../src/pages/playlist/[playlistRedirect].js" /* webpackChunkName: "component---src-pages-playlist-playlist-redirect-js" */),
  "component---src-pages-spotify-[type]-spotify-redirect-js": () => import("./../../../src/pages/spotify/[type]/[spotifyRedirect].js" /* webpackChunkName: "component---src-pages-spotify-[type]-spotify-redirect-js" */),
  "component---src-pages-spotify-spotify-redirect-home-js": () => import("./../../../src/pages/spotify/[spotifyRedirectHome].js" /* webpackChunkName: "component---src-pages-spotify-spotify-redirect-home-js" */),
  "component---src-pages-youtube-youtube-redirect-js": () => import("./../../../src/pages/youtube/[youtubeRedirect].js" /* webpackChunkName: "component---src-pages-youtube-youtube-redirect-js" */)
}

